import { useToast } from 'vuestic-ui'

export default function useUtility() {
    const { init : initToast } = useToast()
    function toast(message: string, color = "success", closeable = false, duration = 2000) {
        initToast({
            message: message,
            color: color,
            closeable: closeable,
            duration: duration
        })
    }

    return {
        toast
    }
}