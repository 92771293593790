import { defineComponent, reactive, onMounted, computed, ref, getCurrentInstance } from 'vue'
import axiosService from '@/services/AxiosService'
import { SelectOption } from '@/services/SelectOptionService'
import selectOptionService from '@/services/SelectOptionService'

export default function useSelectOptions() {
    const selectOptions = reactive({
        industryTypes: [] as Array<SelectOption>,
        projectTypes: [] as Array<SelectOption>,
        // projectSource: [] as Array<SelectOption>,
        projectStatus: [] as Array<SelectOption>,
        companyType: [] as Array<SelectOption>,
        communicationType: [] as Array<SelectOption>
    })

    onMounted(async () => {
        await selectOptionService.waitOptionsLoadComplete()
        selectOptions.industryTypes = selectOptionService.optionCollection.get('industry_type') || []
        selectOptions.projectStatus = selectOptionService.optionCollection.get('ProjectStatus') || []
        // selectOptions.projectSource = selectOptionService.optionCollection.get('CompanySource') || []
        selectOptions.companyType = selectOptionService.optionCollection.get('CompanyType') || []
        selectOptions.projectTypes = selectOptionService.optionCollection.get('ProjectType') || []
        selectOptions.communicationType = selectOptionService.optionCollection.get('CommunicationType') || []
    })

    return {
        selectOptions
    }
}